.drag_drop_list li {
    /* display: grid;
    grid-template-columns: auto auto;
    gap: 15px; */
}
.table_bg_colr {
    background: #f0f0f0;
}
.drag_drop_list li .drop_top, .drag_drop_list li .drop_contant {
    background-color: #ffffff;
    border-top: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    border-left: 1px solid #e9e9e9 !important;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
    /* background-image: linear-gradient(120deg, #9d70ff 0%, #c7adff 100%); */
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
    border: none !important;
}
.progress_border {
    border-left-color: #ec6c1a !important;
}
.drag_drop_container {
    box-shadow: none;
    border: none;
}
.taskic_tit {
    display: flex;
    align-items: center;
}
.dashboard td:nth-child(2) {
    text-align: right;
    /* padding-right: 20px; */
    font-size: 15px;
    font-weight: 600;
}
.active-tabs input:checked+label {
    border-bottom: 1px solid #ccc;
    border-radius: 5px;
}
.selector_s::-ms-expand {
    display: none;
  } 
.selector_s {
-webkit-appearance: none;
appearance: none;
}
.seldct_drop_ic {
    position: relative;
    /* width: -webkit-fill-available; */
}
.seldct_drop_ic .selector_s {
    flex: 1;
    min-width: 150px;
    padding: 5px;
    width: 100%;
    /* display: flex; */
    border: none !important;
    border-bottom: 1px solid #b6adc8 !important;
    padding: 6px 35px;
    font-family: Poppins;
    box-sizing: border-box;
    background: #f8f9fa;
    padding-left: 40px !important;
    background: transparent;
}
.drop_lef_img {
    position: absolute;
    top: 1px;
}
.drop_rit_img {
    position: absolute;
    right: 10px;
    bottom: 7px;
}
.dat_bx_lin {
    border-right: none;
    flex: 1 1;
    min-width: 150px;
    padding: 5px;
    width: 100%;
    /* display: flex; */
    border: none;
    border-bottom: 1px solid #b6adc8;
    padding: 6px 15px;
    font-family: Poppins;
    box-sizing: border-box;
    background: #f8f9fa;
    /* padding-left: 40px !important; */
    background: transparent;
    display: none;
}
@media only screen and (max-width: 991px) {
    .drag_drop_list li {
        display: block;
    }
}
/* #ddlTaskStatus {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
} */
/* #dateRequired {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
} */
 .dat_bx_icon {
    border-right: none;
    flex: 1 1;
    min-width: 150px;
    padding: 5px;
    width: 100%;
    /* display: flex; */
    border: none;
    border-bottom: 1px solid #b6adc8;
    padding: 6px 10px;
    font-family: Poppins;
    box-sizing: border-box;
    background: #f8f9fa;
    /* padding-left: 40px !important; */
    background: transparent;
 }
.dat_bx_icon[type="date"]::-webkit-calendar-picker-indicator {
    /* color: rgba(0, 0, 0, 0); */
    /* opacity: 1; */
    /* display: block; */
    background: url(../images/calendar_ic.png) no-repeat;
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center; 
    width: 20px;
    height: 20px;
    /* border-width: thin; */
}
.dep_dat_flx_bx {
    display: flex;
    align-items: center;
}
.linic_input_boxes_one {
    position: absolute;
    top: -8px;
    right: 0px;
}
.notsk_less_tsk {
    display: flex;
    align-items: center;
}
.notsk_less_tsk_title {
    font-size: 20px;
    padding-left: 3px;
    border-bottom: 1px solid #b6adc8;
}
.notsk_less_tsk_tit_two {
    font-size: 20px;
    position: absolute;
    top: 36px;
}
.notsk_less_tsk .drag_drop_container {
    /* padding-left: 5px !important; */
    padding: 0px !important;
}
.notsk_less_tsk .emp_hav_no_tsk_list_grid .sortable-item {
    padding: 0px;
}
.notsk_less_tsk .emp_hav_no_tsk_list_grid .sortable-item .drop_top {
    margin: 0px;
}
.left_task_scrlbar {
    height: 300px;
    overflow: auto;
    width: 100%;
}
.emp_hav_no_tsk_list_grid {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 15px;
    margin: 0px;
    padding: 0px;
    padding-right: 10px;
}
.footer-social-copyrights  {
    background-color: #000 !important;
}
.add_tsk_inpt_fld1 {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}
.add_tsk_inpt_fld2 {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}
.add_tsk_inpt_fld3 {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}
.add_tsk_inpt_fld4 {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}
.add_tsk_inpt_fld5 {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}
.add_tsk_inpt_fld6 {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}
.add_tsk_fld_rite {
    width: 100%;
}
.datboxsrt [type="date"]::-webkit-calendar-picker-indicator {
    /* color: rgba(0, 0, 0, 0); */
    /* opacity: 1; */
    /* display: block; */
    background: url(../images/calendar_ic.png) no-repeat;
    background-size:     cover;                      
    background-repeat:   no-repeat;
    background-position: center center; 
    width: 20px;
    height: 20px;
    /* border-width: thin; */
}
.issu_nam_inpfld input {
    border-bottom: 1px solid #b6adc8 !important;
    border-top: none;
    border-right: none;
    border-left: none;
    padding-left: 40px;
    font-size: large;
}
.issu_nam_inpfld {
    height: 23px;
}
.datfltbx {
    height: 37px;
}
.allot_hrs_fld {
    border-bottom: 1px solid #b6adc8 !important;
    border-top: none;
    border-right: none;
    border-left: none;
    height: 34px;
    padding-left: 40px;
    font-size: large;
}
.is_bill_radiobtn {
    display: flex;
    position: absolute;
    gap: 10px;
    bottom: 15px;
}
.addtsk_frm_fld {
    padding: 15px;
}
#Description {
    border-radius: 10px;
    height: 100px;
    border: 1px solid #b6adc8;
    padding: 10px;
}
.datfltbx input {
    border-bottom: 1px solid #b6adc8 !important;
    border-top: none;
    border-right: none;
    border-left: none;
}
#TaskListdiv {
    border-top: 1px solid #b6adc8;
    margin-top: 10px;
}
.task_allot_list_grd_bx {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 15px;
    padding-top: 10px;
}
.task_allot_list_grd_bx .progress_border {
    position: relative;
}
/* .progress_border .task_grdbx_lis {
    position: absolute;
    top: -7px;
    right: 3px;
    border: none !important;
    font-size: 20px !important;
} */
.emp_tsk_empoly_name {
    padding: 0px;
    margin: 0px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 20px !important;
}
.emp_hav_tsk_grd_bx {
    padding: 15px !important;
    margin: 0px !important;
}
.emp_hav_tsk_grd_box {
    margin: 0px !important;
}
.task_allot_list_grd_bx #TasklistMohan .drag_drop_list .sortable-item {
    padding: 0px !important;
}
.emphav_tsk_titandcon .Issues {
    color: #000 !important;
    font-size: 20px !important;
    /* font-weight: 600 !important; */
    margin: 0px;
    padding: 0px;
    padding-bottom: 10px;
}    
#progessdiv {
    padding: 0px !important;
}
#pendingdiv {
    padding: 0px !important;
}
#progessdiv .drag_drop_list .sortable-item .progress_border {
    margin-right: 7px;
}
#pendingdiv .drag_drop_list .sortable-item .progress_border {
    margin-left: 7px;
}
.emphav_tsk_titandcon .task_grdbx_lis {
    font-size: 20px !important;
    margin: 0px;
    padding: 0px;
    padding-bottom: 10px;
}
.tim_shet_lis_prog_inprog {
    margin-top: -30px !important;
}
.emp_hav_les_tsk {
    height: 300px;
    overflow: auto;
    margin-left: 10px;
    display: none;
}
.emp_hav_les_tsk #LessHourTasklist1 {
    margin-right: 10px;
}
.drag_drop_container {
    text-align: center;
} 
.emy_les_tsk_def_tsk {
    border-bottom: 1px solid #b6adc8 !important;
    font-size: 22px !important;
    font-weight: 200;
    margin: 0 0 10px;
    padding-left: 0;
    padding-top: 0;
    text-align: left;
}
.less_hrs_tsk_list {
    margin: 0px !important;
}
.emp_hav_tsk_listview p {
    justify-content: space-between;
    margin-bottom: 10px !important;
}
.emp_hav_tsk_listview p:nth-last-child(1) {
    margin-bottom: 0px !important;
}
.emphav_tsk_titandcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #b6adc8;
    margin-bottom: 18px;
}
#TasklistMohan .tdiv1 {
    border: none !important;
    padding: 0px !important;
}
.emp_hav_tsk {
    font-size: 20px;
    padding-left: 3px;
}
.notsk_img {
    /* position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%); */
}

/* scroll start */
/* width */
::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    /* background: red;  */
    background-image: linear-gradient(120deg, #9d70ff 0%, #dfd0ff 100%);
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    /* background: #b30000;  */
    background-image: linear-gradient(120deg, #dfd0ff 0%, #9d70ff 100%);
  }
  /* scroll end */

  